const maskString = (word: string, format: string = '', formatSymbol = '#') => {
  const formatNormalized = format.split('').filter(x => x === formatSymbol);
  if (!word.length || word.length > formatNormalized.length) {
    return word;
  }

  const lastPos = format.length;
  const lastWordPos = word.length;
  let nextPos = 0;
  let nextWordPos = 0;
  let masked = '';
  
  while (nextPos < lastPos && nextWordPos < lastWordPos) {
    const symbol = format.charAt(nextPos);
    if (symbol === formatSymbol) {
      masked += word.charAt(nextWordPos);
      nextWordPos += 1;
    } else {
      masked += symbol;
    }
    nextPos += 1;
  }
  
  return masked || word;
};

export default maskString;
