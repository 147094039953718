import { SelectOption } from '@pluxee-design-system/core';
import { SelectControl } from 'common/forms';
import { useFormikContext } from 'formik';
import { Activity } from 'generated/models';
import { I18nProps, withLazyNamespaces } from 'i18n';
import { get } from 'lodash';
import { useCallback, useMemo } from 'react';
import { ActivityChangeFn, getChildren } from './utils';

interface ActivityTreeProps extends I18nProps {
  activities: Activity[];
  index: number;
  isDisabled?: boolean;
  isFirstChangeable?: boolean;
  name?: string;
  readonly?: boolean;
  onChange: ActivityChangeFn;
}

const ActivityTree = ({
  activities: rawActivities,
  index,
  isDisabled = false,
  isFirstChangeable = false,
  name,
  readonly,
  onChange,
  t,
}: ActivityTreeProps) => {
  const activityField = name ? `${name}.activity` : 'activity';
  const subActivityField = name ? `${name}.subActivity` : 'subActivity';
  const terActivityField = name ? `${name}.terActivity` : 'terActivity';
  const { values } = useFormikContext();
  const activity = get(values, activityField, '');
  const subActivity = get(values, subActivityField, '');

  const activities = useMemo(() => getChildren(rawActivities), [rawActivities]);
  const subActivities = useMemo(
    () => getChildren(rawActivities, activity),
    [rawActivities, activity],
  );
  const terActivities = useMemo(
    () => getChildren(rawActivities, subActivity),
    [rawActivities, subActivity],
  );
  const handleActivityChange = useCallback(
    (option: SelectOption) =>
      onChange(index, option.value, '', '', activityField, subActivityField, terActivityField),
    [activityField, index, onChange, subActivityField, terActivityField],
  );
  const handleSubActivityChange = useCallback(
    (option: SelectOption) =>
      onChange(
        index,
        activity,
        option.value,
        '',
        activityField,
        subActivityField,
        terActivityField,
      ),
    [activity, activityField, index, onChange, subActivityField, terActivityField],
  );
  const handleTerActivityChange = useCallback(
    (option: SelectOption) =>
      onChange(
        index,
        activity,
        subActivity,
        option.value,
        activityField,
        subActivityField,
        terActivityField,
      ),
    [onChange, index, activity, subActivity, activityField, subActivityField, terActivityField],
  );

  return (
    <>
      <SelectControl
        readonly={readonly}
        disabled={(!isFirstChangeable && Boolean(activity)) || isDisabled}
        mandatory
        name={activityField}
        placeholder={t('location_add.form.typeOfActivity.placeholder', 'Type Activity')}
        title={t('location_add.form.typeOfActivity.label', 'Type Activity')}
        maxOptionsBeforeScroll={10}
        notFoundText={t('global_texts.labels.noResults', 'No results matching a query')}
        onChange={handleActivityChange}
        options={activities}
        sortAlphabetically={false}
      />
      <SelectControl
        readonly={readonly}
        disabled={!activity || 0 === subActivities.length || isDisabled}
        mandatory
        name={subActivityField}
        placeholder={t('location_add.form.typeOfCategory.placeholder', 'Choose a sub-category')}
        title={t('location_add.form.typeOfCategory.label', 'choose a sub-category')}
        maxOptionsBeforeScroll={10}
        notFoundText={t('global_texts.labels.noResults', 'No results matching a query')}
        onChange={handleSubActivityChange}
        options={subActivities}
        sortAlphabetically={false}
      />
      <SelectControl
        readonly={readonly}
        disabled={!subActivity || !activity || 0 === terActivities.length || isDisabled}
        mandatory
        name={terActivityField}
        placeholder={t('location_add.form.typeOfSubCategory.placeholder', 'Choose aux category')}
        title={t('location_add.form.typeOfSubCategory.label', 'Choose aux category')}
        maxOptionsBeforeScroll={10}
        notFoundText={t('global_texts.labels.noResults', 'No results matching a query')}
        onChange={handleTerActivityChange}
        options={terActivities}
        sortAlphabetically={false}
      />
    </>
  );
};

export default withLazyNamespaces(ActivityTree, 'location_add');
