import { Box } from '@chakra-ui/react';
import { SelectOption } from '@pluxee-design-system/core';
import ProductLogo from 'components/Products/ProductLogo';
import { Product } from 'generated/models/product';

const ProductWithLogo = ({ isDisabled, label, nameKey }: SelectOption) => {
  const product = { nameKey, name: label } as Product;

  return (
    <Box
      ml={2}
      mr={4}
      h="24px"
      w="24px"
      color={isDisabled ? 'semantic.icon.disabled' : 'semantic.icon.secondary'}
    >
      <ProductLogo product={product} />
    </Box>
  );
};

export default ProductWithLogo;
