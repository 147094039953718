import { Box, useImage } from '@chakra-ui/react';
import { Tooltip, XMark, XMarkStyles, XMarkVariants } from '@pluxee-design-system/core';
import { Product, ProductFull } from 'generated/models';
import useTranslations from 'i18n';

interface ProductLogoProps {
  product: Product | ProductFull;
  logoWidth?: number;
  logoHeight?: number;
  size?: 16 | 24 | 32; // currently only 24 supported
  withTooltip?: boolean;
}

// TODO: replace by SvgSpriteConsumer when all icons included in library
const ProductLogo = ({
  product: { name, nameKey },
  size = 24,
  withTooltip = false,
}: ProductLogoProps) => {
  const { t } = useTranslations();
  const altText = t('product_details.products.*.product.name', name, {
    query: { where: 'product_details.products.*.product.code', is: nameKey },
  });
  const logoUrl = t('product_details.products.*.product.logo.url', '', {
    query: { where: 'product_details.products.*.product.code', is: nameKey },
  });
  const widthPx = `${size}px`;
  const heightPx = `${size}px`;
  const isLoaded = useImage({ src: logoUrl }) === 'loaded';

  const body = (
    <Box
      position="relative"
      width={widthPx}
      height={heightPx}
      lineHeight={heightPx}
      overflow="hidden"
      sx={{
        div: { width: '100%', height: '100%' },
      }}
    >
      {isLoaded && (
        <Box
          as="img"
          src={logoUrl}
          title={altText}
          alt={altText}
          width={widthPx}
          height={heightPx}
          objectFit="cover"
        />
      )}
      {!isLoaded && <XMark style={XMarkStyles.FILLED} variant={XMarkVariants.DEEP_BLUE} />}
    </Box>
  );

  return withTooltip ? (
    <Tooltip aria-label={altText} hasArrow label={altText} placement="right">
      {body}
    </Tooltip>
  ) : (
    body
  );
};

export default ProductLogo;
