import { Flex, Text, VStack } from '@chakra-ui/react';
import {
  Button,
  Divider,
  DividerLook,
  FieldHelpText,
  Icons,
  InputVariant,
  SvgSpriteConsumer,
} from '@pluxee-design-system/core';
import { ActivityChangeFn } from 'components/Form/ActivityTree/utils';
import { FieldArray, useFormikContext } from 'formik';
import { Activity } from 'generated/models';
import { I18nProps, withTranslations } from 'i18n';
import { isEqual } from 'lodash';
import { useCallback } from 'react';
import ActivityTree from '../ActivityTree';

interface ActivityTreeGroupControl extends I18nProps {
  activities: Activity[];
  isChangeAllowed: (index: number, terActivity: string) => Promise<boolean>;
  isDisabled?: boolean;
  isFirstChangeable?: boolean;
  name: string;
  readonly?: boolean;
}

export interface ActivitySelection {
  activity: string;
  subActivity: string;
  terActivity: string;
}

const template: ActivitySelection = {
  activity: '',
  subActivity: '',
  terActivity: '',
};

// TODO: rewrite
const ActivityTreeGroupControl = ({
  activities,
  isChangeAllowed,
  isDisabled,
  isFirstChangeable,
  name,
  readonly,
  t,
}: ActivityTreeGroupControl) => {
  const { errors, initialValues, setFieldValue, touched, values } =
    useFormikContext<Record<string, any>>();
  const formElements = values[name] as any[];
  const hasElements = formElements?.length > 0;
  const isTouched = Boolean(touched[name] || !isEqual(values[name], initialValues[name]));
  const hasError = Array.isArray(errors[name]) ? false : Boolean(errors[name]);

  const handleActivityTreeChange = useCallback<ActivityChangeFn>(
    async (
      index,
      activity,
      subActivity,
      terActivity,
      activityFieldName,
      subActivityFieldName,
      terActivityFieldName,
    ) => {
      if (await isChangeAllowed(index, terActivity)) {
        setFieldValue(activityFieldName, activity);
        setFieldValue(subActivityFieldName, subActivity);
        setFieldValue(terActivityFieldName, terActivity);
      }
    },
    [isChangeAllowed, setFieldValue],
  );

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => {
        const addButton = !isDisabled && (
          <Button
            type="button"
            onClick={() => arrayHelpers.push(template)}
            variant="secondaryOutlined"
            id="Form_ActivityTree_addButton"
            size="md"
            alignSelf="flex-start"
          >
            {t('location_add.buttons.addActivity', 'Add new')}
          </Button>
        );

        return (
          <>
            {formElements.map((_, index) => {
              const deleteButton = (
                <Button
                  type="button"
                  onClick={async () => {
                    if (await isChangeAllowed(index, '')) {
                      arrayHelpers.remove(index);
                    }
                  }}
                  variant="secondaryTextOnly"
                  id={`Form_ActivityTree_${index}_deleteButton`}
                  size="md"
                  alignSelf="flex-end"
                  leftIcon={<SvgSpriteConsumer size={24} svgId={Icons.TRASH24} />}
                >
                  {t('location_add.buttons.removeActivity', 'Remove')}
                </Button>
              );

              return (
                <VStack align="stretch" spacing={4} key={`activity-field-${index}`}>
                  <Text as="div" variant="subtitle.section">
                    {index + 1}. {t('location_add.sectionTexts.addLocation.activity', 'Activity')}
                  </Text>
                  <ActivityTree
                    readonly={readonly}
                    name={`${name}[${index}]`}
                    activities={activities}
                    index={index}
                    isDisabled={isDisabled}
                    isFirstChangeable={isFirstChangeable}
                    onChange={handleActivityTreeChange}
                  />
                  {!readonly && (
                    <>
                      {hasElements && index === formElements.length - 1 ? (
                        <Flex justify="space-between">
                          {addButton}
                          {deleteButton}
                        </Flex>
                      ) : (
                        deleteButton
                      )}
                    </>
                  )}
                  {index + 1 < formElements.length && (
                    <Divider look={DividerLook.SECONDARY} variant="solid" />
                  )}
                </VStack>
              );
            })}
            {isTouched && hasError && (
              <FieldHelpText
                disabled={isDisabled}
                text={errors[name] as string}
                variant={InputVariant.ERROR}
              />
            )}
            {!readonly && !hasElements && addButton}
          </>
        );
      }}
    />
  );
};

export default withTranslations(ActivityTreeGroupControl);
