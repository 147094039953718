import { Activity } from 'generated/models';
import { orderBy, uniq } from 'lodash';

export interface ActivityItem {
  value: string;
  label: string;
  order: number;
}

export type ActivityChangeFn = (
  index: number,

  activity: string,
  subActivity: string,
  terActivity: string,

  activityFieldName: string,
  subActivityFieldName: string,
  terActivityFieldName: string,
) => void;

export const getChildren = (activities: Activity[], parentCode?: string) => {
  const parentId = parentCode
    ? activities.find((activity) => activity.code === parentCode)?.uid
    : undefined;

  return orderBy(
    activities.reduce<ActivityItem[]>((result, activity) => {
      if ((parentId && activity.parentUid === parentId) || (!parentId && !activity.parentUid)) {
        result.push({
          value: activity.code,
          label: activity.name || activity.code,
          order: activity.listOrder || 0,
        });
      }
      return result;
    }, []),
    ['order', 'label'],
    ['desc', 'asc'],
  );
};

export const findParents = (activities: Activity[], childId: string, depth: number) => {
  const result: string[] = [];
  let id: string | null | undefined = childId;
  let currentDepth = 0;

  while (id && currentDepth < depth) {
    const element = activities.find((activity) => activity.uid === id || activity.code === id);
    currentDepth += 1;

    if (element) {
      result.push(element.code); // store element's code instead of id
      id = element.parentUid;
    } else {
      id = null;
    }
  }

  return result;
};

export const uniqProductCodesFromActivities = (
  activities: Activity[],
  selectedActivities: string[],
) =>
  uniq(
    activities.reduce((result, activity) => {
      if (selectedActivities.includes(activity.code)) {
        result.push(...activity.products);
      }
      return result;
    }, [] as string[]),
  );
