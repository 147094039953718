import { ShowModalParams } from 'context/ModalContext';
import { TranslateFn } from 'i18n';

/**
 * Onboarding create additional location
 */
export const additionalCreateModalParams = (t: TranslateFn): ShowModalParams => ({
  confirmText: t('modals.onboarding.dialogAdditionalCreate.confirmButton', 'Finish'),
  cancelText: t('modals.onboarding.dialogAdditionalCreate.cancelButton', 'Create another'),
  reverseOrder: true, // to switch action buttons
  title: t('modals.onboarding.dialogAdditionalCreate.header', 'Do you have more locations?'),
  text: t(
    'modals.onboarding.dialogAdditionalCreate.description',
    'If you have another locations, you can register them now or later in the NGM portal.',
  ),
  ignoreClickOutside: true,
  hideCloseButton: true,
});

export const activityProductImpactModalParams = (
  t: TranslateFn,
  impactedProductNames: string[],
): ShowModalParams => ({
  confirmText: t('global_texts.buttons.confirm', 'Confirm'),
  cancelText: t('global_texts.buttons.cancel', 'Cancel'),
  title: t('modals.onboarding.dialogActivityProductImpact.header', 'Activity change'),
  text: t(
    'modals.onboarding.dialogActivityProductImpact.description',
    'The change will have impact on the following products and these products will be removed from the location.\n Do you want to proceed?',
  ),
  // eslint-disable-next-line react/display-name
  children: (
    <ul
      style={{
        fontWeight: 700,
        listStyleType: 'disc',
        margin: '1rem auto',
        width: 'fit-content',
      }}
    >
      {impactedProductNames.map((productName) => (
        <li key={productName}>{productName}</li>
      ))}
    </ul>
  ),
});

export const activityImpactedProductNotActiveModalParams = (
  t: TranslateFn,
  impactedProductNames: string[],
): ShowModalParams => ({
  cancelText: t('global_texts.buttons.cancel', 'Cancel'),
  title: t(
    'location_add_portal.editPage.modals.activityImpactedProductsNotActive.header',
    'Activity change',
  ),
  text: t(
    'location_add_portal.editPage.modals.activityImpactedProductsNotActive.description',
    'The following products are being changed. You cannot change activity until products are fully ready. Please try to repeat your action in a couple of minutes.',
  ),
  // eslint-disable-next-line react/display-name
  children: impactedProductNames.length > 0 && (
    <ul
      style={{
        fontWeight: 700,
        listStyleType: 'disc',
        margin: '1rem auto',
        width: 'fit-content',
      }}
    >
      {impactedProductNames.map((productName) => (
        <li key={productName}>{productName}</li>
      ))}
    </ul>
  ),
});
