import { HStack } from '@chakra-ui/react';
import { Icons, SvgSpriteConsumer, Tooltip } from '@pluxee-design-system/core';
import { ProductCheckboxItem } from 'components/Products/ProductCheckbox';
import useTranslations from 'i18n';

const CzProductActivityInfo = ({ isAllowed }: ProductCheckboxItem) => {
  const { t } = useTranslations();

  if (isAllowed) return null;

  return (
    <HStack ml={-1} h="24px" w="24px">
      <Tooltip
        hasArrow
        label={t(
          'location_add.sectionTexts.addLocation.productNotAvailable',
          `Selected activities don't permit this product`,
        )}
      >
        <SvgSpriteConsumer key="question" size={16} svgId={Icons.QUESTION_FILLED16} />
      </Tooltip>
    </HStack>
  );
};

export default CzProductActivityInfo;
