import { RadioGroup, RadioGroupProps, Stack, StackDirection } from '@chakra-ui/react';
import {
  FieldHelpText,
  FieldTitle,
  InputVariant,
  Radio,
  SelectOption,
} from '@pluxee-design-system/core';
import ProductCard from 'common/cards/ProductCard';
import withFastField, { WithFastFieldProps } from 'common/forms/withFastField';
import { ComponentType, memo, useCallback } from 'react';

export interface ProductGroupControlProps
  extends Omit<RadioGroupProps, 'isDisabled' | 'name' | 'children'> {
  direction?: StackDirection;
  disabled?: boolean;
  mandatory?: boolean;
  name: string;
  readonly?: boolean;
  renderLeftElement?: ComponentType<SelectOption>;
  title?: string;
  options: readonly SelectOption[];
}

const ProductGroupControl = ({
  direction,
  disabled,
  id,
  mandatory,
  name,
  onChange,
  options,
  readonly,
  renderLeftElement: RenderLeftElement,
  title,
  field,
  field: { setValue },
}: ProductGroupControlProps & WithFastFieldProps<string[]>) => {
  const hasError = Boolean(field.error);
  const variant = readonly
    ? InputVariant.READONLY
    : disabled
      ? 'disabled'
      : field.isTouched && hasError
        ? InputVariant.ERROR
        : field.isTouched
          ? InputVariant.SUCCESS
          : InputVariant.FILLED;

  const handleRadioChange = useCallback(
    (value: string) => {
      setValue([value], true);
    },
    [setValue],
  );

  return (
    <>
      {title && (
        <FieldTitle
          title={title}
          mandatory={Boolean(mandatory)}
          disabled={variant === 'disabled'}
          variant={variant !== 'disabled' ? variant : undefined}
        />
      )}
      <RadioGroup
        id={id}
        isDisabled={variant === 'disabled'}
        value={field.value?.[0] ?? undefined}
        name={name}
        onChange={onChange ?? handleRadioChange}
      >
        <Stack align="stretch" direction={direction} spacing={4}>
          {options.map((option) => (
            <ProductCard
              key={option.value}
              variant={
                (variant === InputVariant.FILLED || variant === InputVariant.SUCCESS) &&
                field.value.includes(option.value)
                  ? 'selected'
                  : variant
              }
            >
              <Radio key={option.value} value={option.value}>
                {RenderLeftElement && <RenderLeftElement {...option} />}
                {option.label}
              </Radio>
            </ProductCard>
          ))}
        </Stack>
      </RadioGroup>
      {field.isTouched && field.error && (
        <FieldHelpText
          disabled={variant === 'disabled'}
          text={field.error}
          variant={InputVariant.ERROR}
        />
      )}
    </>
  );
};

const MemoizedProductGroupControl = memo(ProductGroupControl);

export default withFastField(MemoizedProductGroupControl);
